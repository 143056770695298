var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "mb-2", attrs: { cols: "12", md: "8" } },
        [
          _c(
            "l-map",
            {
              ref: "loggerMap",
              staticStyle: { height: "500px", width: "100%" },
              attrs: { zoom: _vm.zoom, center: _vm.center },
              on: {
                "update:zoom": _vm.zoomUpdated,
                "update:center": _vm.centerUpdated,
                "update:bounds": _vm.boundsUpdated,
              },
            },
            [
              _c("l-tile-layer", { attrs: { url: _vm.url } }),
              _c(
                "l-feature-group",
                { ref: "fg" },
                _vm._l(_vm.mapPoints, function (logger) {
                  return _c(
                    "l-marker",
                    {
                      key: logger.id,
                      attrs: { visible: true, "lat-lng": logger.location },
                    },
                    [
                      _c("l-popup", [
                        _c("p", [
                          _c("b", [
                            _vm._v(_vm._s(logger.name || logger.serial)),
                          ]),
                        ]),
                        logger.status === "OFFLINE" && !logger.alarm
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c("fa-icon", {
                                  style: { color: "gray" },
                                  attrs: { icon: "minus-circle" },
                                }),
                                _vm._v(" Offline "),
                              ],
                              1
                            )
                          : logger.status === "IDLE" && !logger.alarm
                          ? _c(
                              "div",
                              [
                                _c("fa-icon", {
                                  style: { color: "black" },
                                  attrs: { icon: "check-circle" },
                                }),
                                _vm._v(" Idle "),
                              ],
                              1
                            )
                          : logger.status === "RECORDING" && !logger.alarm
                          ? _c(
                              "div",
                              [
                                _c("fa-icon", {
                                  style: { color: "green" },
                                  attrs: { icon: "check-circle" },
                                }),
                                _vm._v(" Recording "),
                              ],
                              1
                            )
                          : logger.alarm
                          ? _c(
                              "div",
                              [
                                _c("fa-icon", {
                                  style: { color: "red" },
                                  attrs: { icon: "exclamation-circle" },
                                }),
                                _vm._v(" Alarm "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("l-tooltip", {
                        attrs: { content: logger.name || logger.serial },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12", md: "4" } },
        [
          _c(
            "b-card",
            { staticStyle: { height: "100%", "padding-bottom": "40px" } },
            [
              _vm.mapPoints && _vm.mapPoints.length > 0
                ? _c(
                    "div",
                    { staticClass: "overflow-auto" },
                    _vm._l(_vm.mapPoints, function (logger) {
                      return _c(
                        "b-card",
                        {
                          key: logger.id,
                          staticClass: "mb-2",
                          on: {
                            mouseenter: function ($event) {
                              return _vm.zoomMapOnHover(logger)
                            },
                            mouseleave: function ($event) {
                              return _vm.resetMapAfterHover()
                            },
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("b-row", [
                                    _c("p", { staticClass: "lead" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(logger.name || logger.serial) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("b-row", [
                                    logger.status === "OFFLINE" && !logger.alarm
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center ml-2" },
                                          [
                                            _c("fa-icon", {
                                              style: { color: "gray" },
                                              attrs: { icon: "minus-circle" },
                                            }),
                                            _vm._v(" Offline "),
                                          ],
                                          1
                                        )
                                      : logger.status === "IDLE" &&
                                        !logger.alarm
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center ml-2" },
                                          [
                                            _c("fa-icon", {
                                              style: { color: "gray" },
                                              attrs: { icon: "check-circle" },
                                            }),
                                            _vm._v(" Idle "),
                                          ],
                                          1
                                        )
                                      : logger.status === "RECORDING" &&
                                        !logger.alarm
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center ml-2" },
                                          [
                                            _c("fa-icon", {
                                              style: { color: "green" },
                                              attrs: { icon: "check-circle" },
                                            }),
                                            _vm._v(" Recording "),
                                          ],
                                          1
                                        )
                                      : logger.alarm
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center ml-2" },
                                          [
                                            _c("fa-icon", {
                                              style: { color: "red" },
                                              attrs: {
                                                icon: "exclamation-circle",
                                              },
                                            }),
                                            _vm._v(" Alarm "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: { cols: "4", "align-self": "center" },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "view-logger",
                                            logger.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("View")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "lead text-center" }, [
                    _vm._v(" No logger locations available "),
                  ]),
              _c(
                "b-btn",
                {
                  staticStyle: { position: "absolute", bottom: "0", left: "0" },
                  attrs: { block: "", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.showAll()
                    },
                  },
                },
                [_vm._v(" Show All ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }