var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c(
                            "b-button-group",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "accent",
                                    pressed:
                                      _vm.$store.getters[
                                        "ui/currentLoggerView"
                                      ] === "Grid",
                                    disabled: _vm.noLoggers,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$store.dispatch(
                                        "ui/set_logger_view",
                                        "Grid"
                                      )
                                    },
                                  },
                                },
                                [_c("fa-icon", { attrs: { icon: "th" } })],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "accent",
                                    pressed:
                                      _vm.$store.getters[
                                        "ui/currentLoggerView"
                                      ] === "List",
                                    disabled: _vm.noLoggers,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$store.dispatch(
                                        "ui/set_logger_view",
                                        "List"
                                      )
                                    },
                                  },
                                },
                                [_c("fa-icon", { attrs: { icon: "list" } })],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "accent",
                                    pressed:
                                      _vm.$store.getters[
                                        "ui/currentLoggerView"
                                      ] === "Map",
                                    disabled: _vm.noLoggers,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$store.dispatch(
                                        "ui/set_logger_view",
                                        "Map"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("fa-icon", {
                                    attrs: { icon: "map-marked" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.claim-logger",
                                  modifiers: { "claim-logger": true },
                                },
                              ],
                              staticStyle: {
                                position: "absolute",
                                right: "20px",
                              },
                              attrs: { variant: "success" },
                            },
                            [_c("fa-icon", { attrs: { icon: "plus" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.noLoggers
                        ? _c(
                            "b-row",
                            { attrs: { "align-h": "center" } },
                            [
                              _c("b-col", { attrs: { cols: "auto" } }, [
                                _c("h5", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "There are no Loggers associated with your account."
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.noLoggers
                        ? _c(
                            "b-row",
                            {
                              staticClass: "mt-1",
                              attrs: { "align-h": "center" },
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.claim-logger",
                                          modifiers: { "claim-logger": true },
                                        },
                                      ],
                                      attrs: { variant: "success" },
                                    },
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "plus" },
                                      }),
                                      _vm._v(" Add Logger "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "keep-alive",
                        [
                          _c(
                            "LoggerSelectPage" +
                              _vm.$store.getters["ui/currentLoggerView"],
                            {
                              tag: "component",
                              on: { "view-logger": _vm.viewLogger },
                            }
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "claimModal",
          attrs: {
            id: "claim-logger",
            title: "Add Logger to Account",
            "ok-only": "",
          },
        },
        [_c("p", [_vm._v(" Logger Setup Instructions Here ")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }