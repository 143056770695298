var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _c("b-table", {
            attrs: {
              items: _vm.$store.getters["loggers/list"],
              fields: _vm.fields,
              currentPage: _vm.page,
              perPage: "10",
              "sort-icon-left": "",
              responsive: "",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    _c("logger-status-icon", {
                      attrs: {
                        status: data.item.status,
                        alarm: data.item.alarm,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _c(
                      "b-btn",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("view-logger", data.item.id)
                          },
                        },
                      },
                      [_vm._v("View")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }