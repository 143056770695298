var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "no-gutters": "" } },
    _vm._l(_vm.$store.getters["loggers/list"], function (logger) {
      return _c(
        "b-col",
        {
          key: logger.id,
          staticClass: "m-1",
          staticStyle: { "max-width": "160px" },
          attrs: { cols: "3", sm: "3", md: "3", lg: "3" },
        },
        [
          _c(
            "b-card",
            {
              attrs: {
                "body-class": "card-grid",
                "header-class": "header-ellipsis",
              },
            },
            [
              _c("span", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(" " + _vm._s(logger.name || logger.serial) + " "),
              ]),
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "center" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("logger-status-icon", {
                            attrs: {
                              size: "5x",
                              status: logger.status,
                              alarm: logger.alarm,
                              textpos: "bottom",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-1", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("view-logger", logger.id)
                                },
                              },
                            },
                            [_vm._v(" View ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }